/**
 * Defines modal to add new pathway
 */
import React, { useState, useEffect } from 'react'
import { createPathway, readPathway, updatePathway } from '../../../api/pathways/private'
import { Title, Text } from '../../../components/typography'
// you need these styles for React Flow to work properly
import styled from 'styled-components'
// imports a close button style
import { FaWindowClose } from 'react-icons/fa'
// functions that interact with the backend api
import { getCategoryID, listCategories } from '../../../api/categories'
import { wrapPromise } from '../../../utils/promise'
import { toast } from 'react-toastify'
import { ExportedPathway } from '../../../utils/exportedPathway'
import { Elements } from 'react-flow-renderer/dist/nocss'

const Button = styled.button`
  background-color: black;
  height: 40px;
  width: 300px;
  color: #fff;
  border-radius: 10px;
`
const CloseButton = styled.button`
  margin-top: 1px;
  margin-right: 2px;
  position: absolute;
  top: 3;
  right: 3;
  background-color: black;
  color: white;
  border-radius: 20px;
`
const Container = styled.div`
  position: fixed;
  background: rgba(158, 158, 158, 0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`
const Display = styled.div`
  position: center;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
`

export const PathwayRestorePopup: React.FC = () => {
  const userToken = localStorage.getItem('userToken') ?? ''
  const [enableInput, setEnableInput] = useState(false)
  const [enableReplaceCheckbox, setEnableReplaceCheckbox] = useState(false)
  const [pathID, setPathID] = useState(0)
  const [pathName, setPathName] = useState('')
  const [pathDes, setPathDes] = useState('')
  const [pathData, setPathData] = useState<Elements<CustomNodeData>>()
  const [catName, setPathCatName] = useState('')
  const [pathReplace, setPathReplace] = useState(false)
  const categoriesList = [{ catID: 0, catName: 'one' }] // sets an initial state
  const [stateCategories, setStateValues3] = useState(categoriesList)

  // Replaces or creates a new pathway using the uploaded data
  const restorePath = async (): Promise<void> => {
    // gets the category id using the category name
    const [res, err] = await wrapPromise(getCategoryID(catName))
    if (err !== null) {
      toast.error('Could Not Find Selected Category In Database')
      return
    }

    const catID = res.catID

    if (pathReplace) {
      // Replace existing pathway
      if (pathData === undefined) {
        toast.error(`No Data For Pathway: ${pathName}`)
        return
      }
      const [_, err] = await wrapPromise(updatePathway(pathID, pathName, pathDes, pathData, catID, userToken))
      if (err !== null) {
        toast.error('Pathway Could Not Be Replaced!')
        return
      }
      toast.success('Pathway Replaced!')
    } else {
      // Create a new pathway
      if (pathData === undefined) {
        toast.error(`No Data For Pathway: ${pathName}`)
        return
      }
      let [res, err] = await wrapPromise(createPathway(pathName, pathDes, catID, userToken))
      if (err !== null) {
        toast.error('Pathway Could Not Be Created!')
        return
      }
      // Get the pathID of the newly created pathway and use update it with the restored data
      ;[res, err] = await wrapPromise(updatePathway(res.pathID, pathName, pathDes, pathData, catID, userToken))
      if (err !== null) {
        toast.error('Pathway Could Not Be Created!')
        return
      }
      toast.success('Pathway Created!')
    }

    void closePage()
  }

  const uploadFile = (): void => {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = (e): void => {
      if (e.target == null) {
        toast.error('Error reading file')
        return
      }
      const files = (e.target as HTMLInputElement).files
      if (files == null) {
        toast.error('Error reading file')
        return
      }
      const file = files[0]
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = async (readerEvent): Promise<void> => {
        const content: string = readerEvent.target?.result as string
        const pathwayData = JSON.parse(content) as ExportedPathway
        // Update input fields and enable them
        setPathName(pathwayData.Name)
        setPathDes(pathwayData.Description)
        setPathData(pathwayData.Data)
        // Attempt to match category, if fails leave as the default selection
        for (let i = 0; i < stateCategories.length; i++) {
          if (stateCategories[i].catID === pathwayData.catID) {
            setPathCatName(stateCategories[i].catName)
            break
          }
        }
        const [_, err] = await wrapPromise(readPathway(pathwayData.ID, userToken)) // read pathway, return response
        if (err === null) {
          // If no error returned, assume pathway exists
          setEnableReplaceCheckbox(true)
          setPathID(pathwayData.ID)
        }

        setEnableInput(true)
      }
    }

    // Display file selection window
    input.click()
  }

  // refreshes the page to update the tables data
  const closePage = (): void => {
    window.location.reload()
  }
  useEffect(() => {
    void (async () => {
      // reads a list of the categories
      const [res, err] = await wrapPromise(listCategories())
      // returns and displays a message if an error is found
      if (err !== null) {
        toast.error('No categories could be found')
        return
      }
      setStateValues3(res)
      setPathCatName(res[0].catName)
    })()
  }, [])

  // Define elements of the form which are dynamically displayed
  const PathwayNameField: React.FC = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
      <Text style={{ padding: '10px', color: 'grey' }}>Pathway Name: </Text>
      <input
        style={{ borderRadius: '8px', width: '300px', height: '30px' }}
        type="text"
        value={pathName}
        onChange={e => setPathName(e.target.value)}
      />
    </div>
  )

  const PathwayDescriptionField: React.FC = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
      <Text style={{ padding: '10px', color: 'grey' }}>Description: </Text>
      <input
        style={{ borderRadius: '8px', width: '300px', height: '30px' }}
        type="text"
        value={pathDes}
        onChange={e => setPathDes(e.target.value)}
      />
    </div>
  )

  const PathwayCategoryField: React.FC = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
      <Text style={{ padding: '10px', color: 'grey' }}>Category: </Text>
      <select style={{ width: '300px', height: '45px' }} value={catName} onChange={e => setPathCatName(e.target.value)}>
        {stateCategories.map(category => {
          return <option value={category.catName}> {category.catName} </option>
        })}
      </select>
    </div>
  )

  const ReplacePathwayCheckbox: React.FC = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
      <Text style={{ padding: '10px', color: 'grey' }}>Replace Existing Pathway With ID {pathID}?: </Text>
      <input
        style={{ borderRadius: '8px', width: '300px', height: '30px' }}
        type="checkbox"
        checked={pathReplace}
        onChange={() => setPathReplace(!pathReplace)}
      />
    </div>
  )

  return (
    <Container>
      <Display>
        <CloseButton onClick={closePage}> {FaWindowClose} x </CloseButton>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Title color="black">Restore Pathway</Title>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Text style={{ padding: '10px', color: 'grey' }}>Pathway File: </Text>
          <input
            style={{ borderRadius: '8px', width: '300px', height: '30px' }}
            type="button"
            value="Upload Pathway File"
            onClick={uploadFile}
          />
        </div>
        {enableInput && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
            <Text style={{ padding: '10px', color: 'grey' }}>Pathway Name: </Text>
            <input
              style={{ borderRadius: '8px', width: '300px', height: '30px' }}
              type="text"
              value={pathName}
              onChange={e => setPathName(e.target.value)}
            />
          </div>
        )}
        {enableInput && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
            <Text style={{ padding: '10px', color: 'grey' }}>Description: </Text>
            <input
              style={{ borderRadius: '8px', width: '300px', height: '30px' }}
              type="text"
              value={pathDes}
              onChange={e => setPathDes(e.target.value)}
            />
          </div>
        )}
        {enableInput && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
            <Text style={{ padding: '10px', color: 'grey' }}>Category: </Text>
            <select style={{ width: '300px', height: '45px' }} value={catName} onChange={e => setPathCatName(e.target.value)}>
              {stateCategories.map(category => {
                return <option value={category.catName}> {category.catName} </option>
              })}
            </select>
          </div>
        )}

        {enableReplaceCheckbox && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
            <Text style={{ padding: '10px', color: 'grey' }}>Replace Existing Pathway With ID {pathID}? </Text>
            <input
              style={{ borderRadius: '8px', width: '300px', height: '30px' }}
              type="checkbox"
              checked={pathReplace}
              onChange={() => setPathReplace(!pathReplace)}
            />
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Button id={pathReplace ? 'warnButtonInput' : ''} hidden={!enableInput} onClick={restorePath}>
            Enter
          </Button>
        </div>
      </Display>
    </Container>
  )
}
